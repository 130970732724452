export const asx = <T extends any>(sx?: T) => {
  if (!sx) return [{}];
  return Array.isArray(sx) ? sx : [sx];
};

export const casx = <T extends any, E extends any>(
  condition?: any,
  thenSx?: T,
  elseSx?: E,
) => {
  return condition ? asx(thenSx) : asx(elseSx || {});
};
