import React, { FC, SVGProps } from 'react';

interface LogoLettersProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

export const LogoLetters: FC<LogoLettersProps> = ({ size = 100, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M320.738 470.422C251.344 470.422 208.297 440.178 205.699 388.596L205.514 384.885H280.289L280.475 386.369C282.145 399.729 297.73 410.119 321.666 410.119C345.23 410.119 359.703 400.1 359.703 386.184V385.998C359.703 373.195 348.941 368.742 319.254 363.176L292.35 358.166C234.83 347.404 209.967 320.129 209.967 277.453V277.268C209.967 222.346 256.354 187.834 319.811 187.834C391.432 187.834 427.613 222.346 432.066 272.072L432.252 274.113H360.074L359.703 272.072C357.105 257.414 342.262 248.137 320.738 248.137C299.029 248.137 287.896 257.6 287.896 269.846V270.031C287.896 283.391 299.4 289.699 326.119 294.709L353.023 299.719C410.543 310.48 437.633 333.488 437.633 378.205V378.391C437.633 434.611 394.586 470.422 320.738 470.422ZM685.449 470.422C603.252 470.422 550.557 416.799 550.557 329.221V328.85C550.557 241.643 603.623 187.834 685.449 187.834C767.275 187.834 820.156 241.643 820.156 328.85V329.221C820.156 416.799 767.275 470.422 685.449 470.422ZM685.449 406.223C717.734 406.223 740 376.164 740 329.221V328.85C740 282.092 717.734 252.033 685.449 252.033C652.979 252.033 630.713 282.092 630.713 328.85V329.221C630.713 376.164 652.979 406.223 685.449 406.223Z"
        fill="white"
      />
      <path
        d="M237.799 831V563.256H316.471V768.656H425.572V831H237.799ZM668.008 838.422C597.314 838.422 550.186 799.271 550.186 738.412V563.256H628.857V730.619C628.857 758.637 642.588 774.223 668.193 774.223C693.799 774.223 707.529 758.637 707.529 730.619V563.256H786.201V738.412C786.201 798.9 739.629 838.422 668.008 838.422Z"
        fill="white"
      />
    </svg>
  );
};
