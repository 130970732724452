import React, { memo } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useStorage } from '@app/hooks';
import {
  ChatIcon,
  DiscountIcon,
  HomeIcon,
  LeaveIcon,
  // MarkerIcon,
  UserIcon,
} from '@app/assets/icons';
import { SYSTEM_ROUTES_ENUM, UI_ROUTES_ENUM } from '../Router';
import { LogoLetters } from '@app/assets/logo';

type MenuItem = {
  path: UI_ROUTES_ENUM;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
};

const MENU: MenuItem[] = [
  {
    path: UI_ROUTES_ENUM.ATTRACTIONS,
    name: 'Объекты',
    Icon: HomeIcon,
  },
  // {
  //   path: UI_ROUTES_ENUM.ROUTES,
  //   name: 'Маршруты',
  //   Icon: MarkerIcon,
  // },
  {
    path: UI_ROUTES_ENUM.DISCOUNTS,
    name: 'Промокоды',
    Icon: DiscountIcon,
  },
  {
    path: UI_ROUTES_ENUM.USERS,
    name: 'Пользователи',
    Icon: UserIcon,
  },
  {
    path: UI_ROUTES_ENUM.REVIEWS,
    name: 'Отзывы',
    Icon: ChatIcon,
  },
];

export const Menu: React.FC = memo(() => {
  const navigate = useNavigate();
  const { clearStorage } = useStorage();

  const handleLogout = () => {
    clearStorage();
    toast.info('Выход из системы...', { autoClose: 1100 });
    setTimeout(() => navigate(SYSTEM_ROUTES_ENUM.MAIN), 1500);
  };

  return (
    <div className="w-full min-w-[285px] max-w-[350px] bg-menu_dark min-h-screen py-10 flex flex-col justify-between select-none">
      <div>
        <Link
          to={SYSTEM_ROUTES_ENUM.MAIN}
          className="self-center pb-7 border-b border-dark_stroke w-full flex justify-center"
        >
          <LogoLetters />
        </Link>

        <div className="flex flex-col mt-20 self-start px-12">
          {MENU.map(({ Icon, name, path }) => (
            <NavLink
              key={name + path}
              to={path}
              className={({ isActive }) =>
                cn('flex items-center mb-9 transition', {
                  'text-green_text': isActive,
                })
              }
            >
              <span>{<Icon color="currentColor" />}</span>
              <span className="ml-4 text-lg">{name}</span>
            </NavLink>
          ))}
        </div>
      </div>
      <div
        className="flex items-center active:text-green_text cursor-pointer transition px-12"
        onClick={handleLogout}
      >
        <span>{<LeaveIcon color="currentColor" />}</span>
        <span className="ml-4 text-lg">Выйти</span>
      </div>
    </div>
  );
});
