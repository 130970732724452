export enum API_REDUCERS_ENUM {
  USERS = 'users_api',
  LOCATIONS = 'locations_api',
  CITIES = 'cities_api',
  CATEGORIES = 'categories_api',
  REGIONS = 'regions_api',
  TAGS = 'tags_api',
  SCHEDULES = 'schedules_api',
  ATTRACTIONS = 'attractions_api',
  ATTRACTION_CATEGORIES = 'attractions_categories_api',
  ATTRACTION_PHOTOS = 'attractions_photos_api',
  ATTRACTION_CONTACTS = 'attractions_contacts_api',
  ATTRACTION_SCHEDULES = 'attractions_schedules_api',
  ATTRACTION_DISCOUNTS = 'attractions_discounts_api',
  ROUTES = 'routes_api',
  ROUTE_PHOTOS = 'route_photos_api',
  ROUTE_STOPS = 'route_stops_api',
  ROUTE_TAGS = 'route_tags_api',
  USER_FAVOURITE_ATTRACTIONS = 'user_favourite_attractions_api',
  USER_CALLED_ATTRACTIONS = 'user_called_attractions_api',
  USER_VIEWED_ATTRACTIONS = 'user_viewed_attractions_api',
  ATTRACTIONS_REVIEWS = 'attractions_reviews_api',
  ROUTE_REVIEWS = 'route_reviews_api',
  DISCOUNTS = 'discounts_api',
  GROUPS = 'groups_api',
  GROUP_KINDS = 'group_kinds_api',
  SUBGROUPS = 'subgroups_api',
}
