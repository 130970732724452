import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { AxiosResponse } from 'axios';
import { axiosBaseQuery } from '@app/core/services/api';
import {
  AttractionContactDeleteRequest,
  AttractionReadRequest,
  ContactCreateRequest,
  ContactUpdateRequest,
  ListRequest,
} from '@app/core/types/requests';
import {
  AttractionContactCRUResponse,
  ListResponse,
} from '@app/core/types/responses';
import { API_REDUCERS_ENUM } from '@app/core/store/reducers';
import AttractionContact, {
  AttractionContactExpand,
} from '@app/core/models/AttractionContact';
import ContactKind from '@app/core/models/ContactKind';
import Contact from '@app/core/models/Contact';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export const attractionsContactsApi = createApi({
  reducerPath: API_REDUCERS_ENUM.ATTRACTION_CONTACTS,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Attraction Contacts'],
  endpoints: build => ({
    getAttractionContactsList: build.query<
      AxiosResponse<ListResponse<AttractionContact>>,
      ListRequest<AttractionContactExpand>
    >({
      query: ({ expand, ...params }) => ({
        url: 'attraction_contacts/',
        method: 'GET',
        params: { expand: expand?.join(','), ...params },
      }),
      transformResponse: (
        response: AxiosResponse<ListResponse<AttractionContact>>,
      ) => {
        response.data.results = response.data.results.map(
          u => new AttractionContact(u),
        );
        return response;
      },
      providesTags: res =>
        res?.data.results.length
          ? [
              ...res.data.results.map(
                ({ id }) => ({ type: 'Attraction Contacts', id } as const),
              ),
            ]
          : [{ type: 'Attraction Contacts', id: 'LIST' }],
    }),
    getAttractionContact: build.query<
      AttractionContactCRUResponse,
      AttractionReadRequest
    >({
      query: ({ id, expand, ...params }) => ({
        url: `attraction_contacts/${id}/`,
        method: 'GET',
        params: { expand: expand?.join(','), ...params },
      }),
      providesTags: (_, __, { id }) => [{ type: 'Attraction Contacts', id }],
    }),
    createAttractionContact: build.mutation<
      AttractionContact[],
      ContactCreateRequest[]
    >({
      queryFn: async (data, _queryApi, _extraOptions, fetchWithBQ) => {
        const { data: kindsData, error } = (await fetchWithBQ({
          url: 'contact_kinds/',
          method: 'GET',
        })) as QueryReturnValue<
          AxiosResponse<ListResponse<ContactKind>>,
          FetchBaseQueryError
        >;

        if (error) return { error };

        const kinds = kindsData?.data.results as ContactKind[];
        const getKindId = (kindName: string) =>
          kinds.find(kind => kind.name === kindName)!.id;

        const contactsCreatePromises = await Promise.all(
          data.map(
            async ({ kind, value }) =>
              (await fetchWithBQ({
                url: 'contacts/',
                method: 'POST',
                data: {
                  value: value,
                  kind: getKindId(kind),
                },
              })) as QueryReturnValue<
                AxiosResponse<Contact>,
                FetchBaseQueryError
              >,
          ),
        );

        if (!contactsCreatePromises.every(u => u.data)) {
          return {
            error: [contactsCreatePromises.map(u => u.error)],
          };
        }
        const contactsCreate = contactsCreatePromises.map(
          ({ data: localData }) => localData as AxiosResponse<Contact>,
        );

        const attractionContactsCreatePromises = await Promise.all(
          contactsCreate
            .map(({ data: { id } }) => id)
            .map(
              async (contact, index) =>
                (await fetchWithBQ({
                  url: 'attraction_contacts/',
                  method: 'POST',
                  data: {
                    attraction: data[index].attraction,
                    contact: contact,
                  },
                })) as QueryReturnValue<
                  AxiosResponse<AttractionContact>,
                  FetchBaseQueryError
                >,
            ),
        );

        if (!attractionContactsCreatePromises.every(u => u.data)) {
          return {
            error: [attractionContactsCreatePromises.map(u => u.error)],
          };
        }

        const attractionContacts = attractionContactsCreatePromises.map(
          attractionContact =>
            attractionContact.data as AxiosResponse<AttractionContact>,
        );

        return {
          data: attractionContacts.map(({ data: localData }) => localData),
        };
      },
      invalidatesTags: [{ type: 'Attraction Contacts', id: 'LIST' }],
    }),
    updateAttractionContact: build.mutation<
      AttractionContact[],
      ContactUpdateRequest[]
    >({
      queryFn: async (query, _queryApi, _extraOptions, fetchWithBQ) => {
        const { data: kindsData, error } = (await fetchWithBQ({
          url: 'contact_kinds/',
          method: 'GET',
        })) as QueryReturnValue<
          AxiosResponse<ListResponse<ContactKind>>,
          FetchBaseQueryError
        >;

        if (error) return { error };

        const kinds = kindsData?.data.results as ContactKind[];
        const getKindId = (kindName: string) =>
          kinds.find(kind => kind.name === kindName)!.id;

        const contactsCreatePromises = await Promise.all(
          query.map(
            async ({ kind, value, attraction, id }) =>
              (await fetchWithBQ({
                url: `contacts/${id}/`,
                method: 'PATCH',
                data: {
                  value,
                  kind: getKindId(kind),
                  attraction,
                },
              })) as QueryReturnValue<
                AxiosResponse<AttractionContact>,
                FetchBaseQueryError
              >,
          ),
        );

        if (!contactsCreatePromises.every(u => u.data)) {
          return {
            error: contactsCreatePromises.map(u => u.error),
          };
        }

        return { data: contactsCreatePromises.map(u => u.data!.data) };
      },
      invalidatesTags: (_, __, array) =>
        array.map(({ id }) => ({ type: 'Attraction Contacts', id: id })),
    }),
    /**
     * @returns `response.status === 204` on success
     */
    deleteAttractionContact: build.mutation<
      AxiosResponse,
      AttractionContactDeleteRequest
    >({
      query: data => ({
        url: `attraction_contacts/${data.id}/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Attraction Contacts', id }],
    }),
  }),
});

export const {
  useCreateAttractionContactMutation,
  useDeleteAttractionContactMutation,
  useGetAttractionContactQuery,
  useGetAttractionContactsListQuery,
  useUpdateAttractionContactMutation,
  useLazyGetAttractionContactQuery,
  useLazyGetAttractionContactsListQuery,
} = attractionsContactsApi;

export default attractionsContactsApi;
