import React, { FC, SVGProps } from 'react';

interface LogoTopologyProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

export const LogoTopology: FC<LogoTopologyProps> = ({
  size = 100,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 767 749"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9245 191.861C-42.4889 -1.69239 155.375 -21.3938 298.166 37.0493C381.479 67.1058 436.191 -26.3677 539.399 10.9364C642.607 48.2405 518.882 165.748 676.803 226.057C834.724 286.365 757.628 556.198 619.603 559.306C481.578 562.415 519.504 607.802 381.479 711.632C243.454 815.461 10.9248 671.219 68.7461 518.894C126.567 366.569 68.7455 401.386 10.9245 191.861Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M307.988 342.493C301.825 324.058 330.691 294.318 353.903 307.011C375.158 318.633 396.802 310.794 411.102 315.976C448.748 329.617 434.901 336.837 451.73 353.038C472.208 372.752 481.98 424.375 434.901 424.375C396.18 424.375 407.766 443.181 378.772 451.514C349.778 459.847 316.896 445.663 316.896 408.319C316.896 394.296 318.252 373.197 307.988 342.493Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M37.9263 204.615C-11.1916 26.9811 171.308 6.36708 303.229 60.651C380.9 89.0317 432.607 3.34317 527.732 37.727C624.98 72.8799 511.244 180.362 656.338 236.66C801.764 293.278 732.565 543.274 602.808 546.1C473.811 548.926 509.342 591.896 381.229 687.044C253.115 782.192 38.7365 649.773 91.3013 507.901C143.866 368.149 91.424 397.883 37.9263 204.615Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M64.9283 217.369C20.1058 55.6551 187.243 34.1285 308.292 84.2532C380.322 110.958 429.022 33.0546 516.065 64.5182C607.352 97.5199 503.605 194.976 635.873 247.264C768.804 300.192 707.503 530.35 586.013 532.893C466.043 535.437 499.18 575.991 380.979 662.457C262.777 748.924 66.5483 628.329 113.857 496.909C161.165 369.73 114.103 394.381 64.9283 217.369Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M91.9314 230.124C51.4044 84.3298 203.178 61.8906 313.357 107.856C379.744 132.885 425.439 62.7666 504.399 91.31C589.726 122.161 495.968 209.59 615.409 257.869C735.845 307.106 682.441 517.427 569.219 519.688C458.277 521.949 489.019 560.086 380.73 637.871C272.441 715.656 94.3612 606.884 136.413 485.918C178.465 371.312 136.782 390.879 91.9314 230.124Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M118.934 242.879C82.7022 113.005 219.112 89.6536 318.42 131.46C379.166 154.813 421.854 92.4797 492.732 118.103C572.099 146.802 488.33 224.206 594.944 268.475C702.886 314.022 657.379 504.505 552.425 506.483C450.51 508.462 478.858 544.183 380.481 613.286C282.103 682.39 122.173 585.441 158.969 474.928C195.764 372.894 159.461 387.378 118.934 242.879Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M145.935 256.281C113.999 142.327 235.046 118.062 323.483 155.71C378.588 177.387 418.269 122.838 481.064 145.541C554.472 172.089 480.691 239.467 574.479 279.726C669.926 321.583 632.316 492.229 535.63 493.925C442.743 495.62 468.696 528.925 380.23 589.347C291.765 649.769 149.985 564.644 181.524 464.583C213.063 375.122 182.14 384.523 145.935 256.281Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M172.939 270.448C145.299 172.413 250.982 147.236 328.548 180.724C378.011 200.726 414.687 153.962 469.399 173.745C536.846 198.142 473.055 255.493 554.016 291.743C636.968 329.909 607.255 480.718 518.837 482.131C434.978 483.544 458.536 514.432 379.983 566.173C301.429 617.913 177.799 544.611 204.081 455.004C230.364 378.116 204.82 382.434 172.939 270.448Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M199.943 284.656C176.598 202.542 266.918 176.452 333.613 205.781C377.435 224.107 411.104 185.129 457.734 201.991C519.221 224.237 465.418 271.562 533.553 303.802C604.01 338.277 582.194 469.249 502.044 470.379C427.212 471.51 448.376 499.982 379.734 543.041C311.093 586.1 205.612 524.621 226.638 445.467C247.664 381.152 227.501 380.386 199.943 284.656Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M226.948 298.927C207.899 232.732 282.855 205.73 338.679 230.9C376.859 247.549 407.522 216.356 446.07 230.298C501.596 250.393 457.782 287.692 513.091 315.922C571.053 346.707 557.134 457.841 485.251 458.689C419.448 459.537 438.217 485.592 379.487 519.97C320.758 554.347 233.427 504.693 249.196 435.991C264.966 384.249 250.182 378.4 226.948 298.927Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M253.955 313.287C239.201 263.012 298.794 235.097 343.747 256.108C376.285 271.082 403.942 247.673 434.407 258.695C483.974 276.639 450.149 303.911 492.63 328.132C538.098 355.227 532.076 446.524 468.461 447.089C411.686 447.654 428.06 471.293 379.242 496.989C330.424 522.685 261.244 484.854 271.757 426.605C282.269 387.436 272.866 376.504 253.955 313.287Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
      <path
        d="M280.965 327.784C270.507 293.429 314.737 264.602 348.819 281.453C375.716 294.751 400.366 279.128 422.749 287.23C466.355 303.022 442.519 320.268 472.174 340.479C505.147 363.883 507.022 435.343 451.675 435.626C403.927 435.909 417.907 457.131 379.001 474.145C340.095 491.16 289.064 465.152 294.321 417.356C299.577 390.76 295.553 374.744 280.965 327.784Z"
        stroke="#86FF8C"
        strokeWidth="3.73041"
      />
    </svg>
  );
};
