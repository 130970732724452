import React, { FC, useState } from 'react';
import { Group, Region } from '@app/core/models';
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ConfirmationModal } from '../ConfirmationModal';
import {
  useCreateRegionMutation,
  useDeleteRegionMutation,
  useUpdateRegionMutation,
} from '@app/core/store/regions';

const validationSchema = yup.object().shape({
  region: yup.string().required('Введите название'),
});

interface FormikData {
  region: string;
}

interface RegionDialogProps extends DialogProps {
  region?: Region;
  onSave?: () => void;
}

export const RegionDialog: FC<RegionDialogProps> = ({
  region,
  onClose,
  onSave,
  ...dialogProps
}) => {
  const [confirm, setConfirm] = useState(false);

  const [updateRegion] = useUpdateRegionMutation();
  const [createRegion] = useCreateRegionMutation();
  const [deleteRegion] = useDeleteRegionMutation();

  const submitApi = async (values: FormikData, id?: Group['id']) => {
    if (id) {
      try {
        await updateRegion({ ...values, id }).unwrap();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    } else {
      try {
        await createRegion(values).unwrap();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  };

  const handleFormikSubmit = async (values: FormikData) => {
    const isSubmitted = await submitApi(values, region?.id);
    if (isSubmitted) {
      onSave?.();
      onClose?.({}, 'backdropClick');
    }
  };

  const { values, handleChange, handleSubmit, dirty, isValid } =
    useFormik<FormikData>({
      validationSchema,
      onSubmit: handleFormikSubmit,
      initialValues: {
        region: region?.region ?? '',
      },
    });

  const handleCancelClick = () => {
    onClose?.({}, 'backdropClick');
  };

  const handleAcceptClick = () => {
    handleSubmit();
  };

  const handleDeleteClick = () => {
    setConfirm(true);
  };

  const handleConfirm = async () => {
    if (!region) return;
    try {
      await deleteRegion({ id: region.id }).unwrap();
      onClose?.({}, 'backdropClick');
    } catch (error) {
      console.error(error);
      setConfirm(false);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '20px',
          alignItems: 'flex-start',
          gap: '16px',
        },
      }}
      {...dialogProps}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 0 16px 0',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: '30px' }}>
          {region ? region.region : 'Новый регион'}
        </Typography>
        {region ? (
          <Button color="error" onClick={handleDeleteClick}>
            Удалить
          </Button>
        ) : null}
      </Box>
      <TextField
        label="Название *"
        name="region"
        value={values.region}
        onChange={handleChange}
        fullWidth
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0 0 0',
          width: '100%',
        }}
      >
        <Button variant="contained" onClick={handleCancelClick}>
          {region ? 'Закрыть без изменений' : 'Отмена'}
        </Button>
        <Button
          onClick={handleAcceptClick}
          disabled={region ? !dirty || !isValid : !isValid}
        >
          {region ? 'Сохранить изменения' : 'Создать'}
        </Button>
      </Box>
      <ConfirmationModal
        open={confirm}
        onConfirm={handleConfirm}
        onClose={() => setConfirm(false)}
      />
    </Dialog>
  );
};
